import { useState } from 'react';

import { css } from '@emotion/react';
import {
  Typography,
  Box,
  FormControlLabel,
  Radio,
  FormGroup,
  RadioGroup,
} from '@mui/material';

type WidgetsPremiumPriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

export const WidgetsPremiumPrice = ({ taxes }: WidgetsPremiumPriceProps) => {
  const [price, setPrice] = useState(60);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormGroup
        sx={{ flexDirection: 'row', transform: 'scale(0.8)' }}
        color="primary"
      >
        <Typography gutterBottom component="p" variant="body1">
          Niveau de l'équipe 1
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group"
          name="row-radio-buttons-group"
          value={price}
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        >
          <FormControlLabel
            value={600}
            control={<Radio />}
            label={`Pro (${600 * taxes.value}€ ${taxes.id})`}
          />
          <FormControlLabel
            value={240}
            control={<Radio />}
            label={`National (${240 * taxes.value}€ ${taxes.id})`}
          />
          <FormControlLabel
            value={120}
            control={<Radio />}
            label={`Regional (${120 * taxes.value}€ ${taxes.id})`}
          />
          <FormControlLabel
            value={60}
            control={<Radio />}
            label={`Départemental (${60 * taxes.value}€ ${taxes.id})`}
          />
        </RadioGroup>
      </FormGroup>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: bolder;
            font-size: 1.5em;
          `}
        >
          {Math.ceil(price * taxes.value)}€
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: lighter;
          `}
        >
          {taxes.id}
        </Typography>
      </Box>
    </Box>
  );
};
